.seats-5 {
  .layout-7 .card-sizer {
    max-width: 5.5vmin;
  }

  &.seat-1 {
    bottom: 8vmin;
    left: -22vmin;

    &.layout-7 {
      left: -18vmin;
    }

    .player-bet.chip-stacks-container {
      top: -1vmin;
      left: 32vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 3.4vmin;
    }
  }

  &.seat-2 {
    top: -14vmin;
    left: -4vmin;

    &.layout-2 .player-bet.chip-stacks-container {
      top: 22vmin;
      left: 30vmin;
    }

    &.layout-7 {
      .player-bet.chip-stacks-container {
        top: 28vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 22vmin;
      left: 37vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 3.4vmin;
    }
     
    .layout-5 .player-bet {
      top: 24vmin;
      left: 40vmin;
    }
}

  &.seat-3 {
    top: -14vmin;
    right: -4vmin;

    &.layout-2 .player-bet.chip-stacks-container {
      top: 22vmin;
      right: 30vmin;
    }
    
    &.layout-7 {
      .player-bet.chip-stacks-container {
        top: 28vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 22vmin;
      right: 37vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 32.4vmin;
    }

    .layout-5 .player-bet {
      top: 24vmin;
      right: 40vmin;
    }
 }

  &.seat-4 {
    bottom: 8vmin;
    right: -22vmin;

    &.layout-7 {
      right: -18vmin;
    }

    .player-bet.chip-stacks-container {
      top: -1vmin;
      right: 32vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 32.4vmin;
    }
  }
}