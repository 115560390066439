/* .video-tile {
  height: 20vh;
  margin-left: 10px;
} */

video {
  max-width: 100%;
  max-height: 100%;
}

/* .oi-microphone {
  top: -8px;
  right: 40px;
  z-index: 100;
}

.oi-video {
  top: -6px;
  right: 32px;
  z-index: 100;
} */