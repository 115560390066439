
.player-tile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.7vmin;
  width: 100%;
  height: 100%;
}

.player-tile .hand {
  width: 100%;
  position: absolute;
  top: 18vh;
  right: .5%;
  z-index: 20;
}

.player-tile .folded {
  display: none;
}

.player-tile .video-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  position: relative;
}

.player-tile .video-tile video {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  clip-path: circle(34% at center);
  z-index: 12;
}

.player-tile .video-tile .user-icon {
  z-index: 11;
  position: absolute;
  font-size: 10vmin;
  top: 27%;
  color: lightsteelblue;
}

.player-tile .video-tile .circle {
  z-index: 11;
  position: absolute;
  top: .25vmin;
  border: .6vmin solid lightsteelblue;
  border-radius: 50%;
  background: rgba(0,0,0,0);
  width: 21vmin;
  height: 21vmin;
}

.player-tile .player-action-meter {
  position: absolute;
  top: -11vmin;
  left: -2vmin;
}

.player-tile-shadow {
  position: absolute;
  top: 50%;
  z-index: 10;
  height: 1px;
  width: 1px;
  border-radius: 50%;
}

.player-tile-shadow.action {
  box-shadow : 0 0 .3em white, 0 0 1em gray, 0 0 6em 8em #007bff;
}

.player-tile-shadow.all-in {
  box-shadow : 0 0 .3em white, 0 0 1em gray, 0 0 6em 8em #dc3545;
}

.video-tile:hover .player-info .player-name {
  width: 100%;
} 

.player-info {
  width: 100%;
  position: absolute;
  top: 0.3vh;
  display: flex;
  flex-direction: row;
  padding-left: 2%;
  padding-right: 2%;
}

.player-info .badge {
  font-size: 1.8vmin;
}

.player-info .player-name {
  position: absolute;
  display: flex;
  flex-direction: row;
  transition: .5s ease;
  width: 0;
  overflow: hidden;
  z-index: 20001;
}

.player-info .player-stack {
  position: absolute;
  top: 1vmin;
  left: 32vmin;
  max-width: 10vw;
}

.player-tile .out-button {
  position: absolute;
  bottom: 9.6vmin;
  right: 3.6vmin;
  height: 4.2vmin;
  width: 4.2vmin;
  border-radius: 50%;
  background-color: #dc3545;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4vmin;
  font-weight: 600;
  z-index: 20;
}

.oi {
  color: white;
}

.player-tile .oi-microphone {
  font-size: 70%;
  margin-left: .6vw;
}

.player-tile .action-timer {
  position: absolute;
  top: 12vh;
  width: 74%;
  height: 1.4vh;
  z-index: 1003;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.4);
}

.player-tile .dealer-button {
  height: 4.1vmin;
  width: 4.1vmin;
  font-weight: bold;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 9.6vmin;
  right: 3.6vmin;
  z-index: 20;
}

.high-declaration {
  height: 4.2vmin !important;
  width: 4.2vmin !important;
  font-size: 1em;
  position: absolute;
  bottom: 9.6vmin;
  left: 3.5vmin;
  z-index: 20;
}

.low-declaration {
  height: 4.2vmin !important;
  width: 4.2vmin !important;
  font-size: 1em;
  position: absolute;
  bottom: 4.6vmin;
  left: 4.6vmin;
  z-index: 20;
}

.discard-badge {
  height: 4.1vmin;
  width: 4.1vmin;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 4.6vmin;
  right: 4.6vmin;
  background-color: #B23CFD;
  z-index: 20;
}

.discard-badge .badge {
  font-size: 1.7vmin;
}