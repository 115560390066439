
.seats-3 {
  .community-cards-outer {
    top: 14vmin;
  }

  &.seat-1 {
    top: -17vmin;
    left: -4vmin;
  }

  &.seat-2 {
    top: -17vmin;
    right: -4vmin;
  }
}