.game-layer .my-cards {
  position: absolute;
  bottom: 3vh;
}

.my-stack.chip-stacks-container {
  position: absolute;
  bottom: 2vmin;
  left: -10vmin;
}

.my-bet.chip-stacks-container {
  position: absolute;
  bottom: 27vmin;
}

.my-tile, .my-actions {
  flex-grow: 2;
}

.my-info, .my-info .badge {
  font-size: 1.7vmin;
}

.my-tray {
  position: absolute;
  right: 0vmin;
  display: flex;

  &.hand-controls {
    bottom: 4vmin;
    width: 37.7vw;
    justify-content: flex-end;

    &:hover .chip-increments {
      opacity: 1;
    }
  }

  &.session-controls {
    bottom: 8vmin;
    width: 100vw;
    justify-content: center;

    .action-button {
      margin-left: 1vw;
      margin-right: 1vw;
    }

    .my-stack {
      bottom: 0;
    }
  }
}

.actions {
  display: flex;
  position: absolute;
  bottom: 1vmin;

  .base-timer {
    position: absolute;
    width: 9.1vmin;
    bottom: -1.1vmin;
    z-index: 0;
  }
}

.my-badges {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.6vh;

  * {
    margin-left: .5vw;
    margin-right: .5vw;
  }

  .badge {
    border-radius: 50%;
    width: 4.4vmin;
    height: 4.4vmin;
    background-color: #B23CFD;

    svg {
      font-size: 2.4vmin;
      position: relative;
      top: .5vmin;
      margin: unset;
    }
  }

  .dealer-button {
    bottom: 10.2vmin;
    right: 2vmin;
    font-size: 1.3em;
    font-weight: 700;
    color: black;
    background-color: #ffc107;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .discard {
    display: flex;
    flex-direction: column;
    font-size: 2vmin;

    svg {
      position: relative;
      top: .1vmin;
      left: .6vmin;
      font-size: 1.6vmin;
    }
  }

  .no-bid {
    font-weight: bold;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;

    svg {
      position: relative;
      top: 0vmin;
      left: .05vmin;
      font-size: 1em;
    }
  }

  .high-low-toggle {
    font-size: 4.4vmin;
    border-radius: 50%;

    &.highlight:not(.winner) {
      border-radius: 50%;
      box-shadow: 0 0 .6vmin .6vmin lightgray;
      z-index: 1000;
    }

    &.highlight.winner {
      border-radius: 50%;
      box-shadow: 0 0 .6vmin .6vmin #28a745;
      z-index: 1000;
    }
  }
}

.auto-actions {
  position: absolute;
  bottom: -3.4vh;
  right: 3vw;
  display: flex;

  .auto-check {
    font-weight: 300;
    font-size: 1vw;
  }
}

.out-of-play-stack {
  position: relative;
  top: 1vmin;
  left: -.4vmin;
  cursor: default;
}