.pot-container {
  display: flex;
  align-items: center;
  position: relative;

  .side-pot-icon {
    margin-left: 1vw;
  }

  &:hover {
    .side-pot-container {
      opacity: 1;
    }
  }

  .badge {
    font-size: 3vmin;
  }

  .side-pot-container {
    display: flex;
    position: absolute;
    left: 11vmin;
    opacity: 0;
    transition: .5s ease;
    z-index: 50000;
    border-radius: 1vmin;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1vmin 1vmin 1vmin 1vmin;

    .side-pot {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-left: .4vw;
      margin-right: .4vw;
          z-index: 1000;

    }

    .chip-stacks-container {
      height: auto;
      font-size: 1.2vmin;

      .chip-stack {
        width: 1.2vmin;
      }
    }
  }
}

