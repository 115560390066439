.action-button.btn {
  font-size: 3vmin;
	border-radius: 50%;
	width: 7vmin;
	height: 7vmin;
	box-shadow: .4vmin .4vmin 1vmin .1vmin rgba(0, 0, 0, .4);
}

.action-button:focus {
	box-shadow: 1px 1px 1px 1px #263666;
}

.action-button-label {
	font-weight: bold;
	text-transform: uppercase;
  font-size: 1.7vmin;
}
