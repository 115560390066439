.my-video-preview {
  width: 32vmin;
  position: absolute;
  bottom: 1vh;
  left: 1vw;

  video {
    max-width: 100%;
    width: 100%;
    height: 100%;
    clip-path: circle(34% at center);
    z-index: 12;
  }

  .circle {
    z-index: -1;
    position: absolute;
    bottom: 1.1vmin;
    left: 7.4vmin;
    border: .6vmin solid lightsteelblue;
    border-radius: 50%;
    background: rgba(0,0,0,0);
    width: 17.2vmin;
    height: 17.2vmin;
  }

  .user-icon {
    z-index: -1;
    position: absolute;
    bottom: 4.9vmin;
    left: 11.6vmin;
    font-size: 10vmin;
    color: lightsteelblue;
  }

  .btn, .out-badge, .wss-alert {
    width: 3.5vmin;
    height: 3.5vmin;
    font-size: 1.4vmin;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .out-badge {
    bottom: .5vmin;
    right: -.6vmin;
    color: white;
    font-weight: 500;
    background-color: #dc3545;
    display: flex;
    justify-content: center;
  }

  .wss-alert {
    bottom: 15vmin;
    right: 4.4vmin;
    font-size: 1.3em;
    font-weight: 700;
    background-color: #dc3545;
    display: flex;
    justify-content: center;
  }

  .mute-button {
    bottom: 10.2vmin;
    left: 2vmin;
  }

  .video-button {
    bottom: 5.2vmin;
    left: 2vmin;

    svg {
      position: absolute;
      left: 1vmin;
      top: 1vmin;
    }
  }

  .settings-button {
    bottom: .5vmin;
    left: 4.4vmin;

    svg {
      position: absolute;
      left: 1.05vmin;
      top: 1vmin;
    }
  }

  .sit-in-out-button {
    bottom: .5vmin;
    right: 4.4vmin;

    svg {
      position: absolute;
      left: 1vmin;
      top: 1vmin;
    }
  }

  .add-chips-button {
    bottom: 5.2vmin;
    right: 2vmin;

    svg {
      position: absolute;
      left: 1.1vmin;
      top: 1vmin;
    }
  }
}

.my-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
}