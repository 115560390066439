.seats-7 {
  &.player-tile-layout {
    width: 32vmin;
    height: 18vmin;

    .video-tile {
      svg {
        font-size: 8vmin;
      }

      .circle {
        height: 18vmin;
        width: 18vmin;
      }

      .base-timer {
        height: 19vmin;
        width: 19vmin;
        top: -.1vmin;
      }
    }

    .high-declaration {
      bottom: 7vmin;
      left: 1.2vmin;
    }

    .low-declaration {
      bottom: 2.6vmin;
      left: 2.2vmin;
    }

    .dealer-button, .out-button {
      bottom: 7.4vmin;
      right: 1.4vmin;
    }

    .discard-badge {
      bottom: 2.8vmin;
      right: 2.1vmin;
    }
  }

  .card-sizer {
    max-width: 6vmin;
  }

  .layout-7 .card-sizer {
    max-width: 5.5vmin;
  }

  .player-tile .hand {
    top: 15vmin;
  }

  .player-info .player-stack {
    right: 4.4vmin;
  }

  &.seat-1 {
    bottom: 4vmin;
    left: -18vmin;

    &.layout-7 {
      left: -14vmin;

      .player-bet.chip-stacks-container {
        left: 40vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 4vmin;
      left: 32vmin;
    }

    .player-stack {
      top: -1vmin;
      left: -4vmin;
    }
  }

  &.seat-2 {
    top: 6vmin;
    left: -18vmin;

    &.layout-5 .player-bet.chip-stacks-container {
      top: 18vmin;
      left: 34vmin;
    }

    &.layout-7 {
      top: 8vmin;
      left: -14vmin;

      .player-bet.chip-stacks-container {
        left: 40vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 18vmin;
      left: 30vmin;
    }

    .player-stack {
      top: -1vmin;
      left: -4vmin;
    }
 }

  &.seat-3 {
    top: -17vmin;
    left: 11vmin;

    &.layout-7 {
      left: 10vmin;

      .player-bet.chip-stacks-container {
        right: 1vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 26vmin;
      left: 10vmin;
    }

    .player-stack {
      top: -1vmin;
      left: -4vmin;
    }
  }

  &.seat-4 {
    top: -17vmin;
    right: 11vmin;

    &.layout-7 {
      right: 10vmin;

      .player-bet.chip-stacks-container {
        left: 1vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 26vmin;
      right: 10vmin;
    }

    .player-stack {
      top: -1vmin;
      left: 29.4vmin;
    }
  }

  &.seat-5 {
    top: 6vmin;
    right: -18vmin;

    &.layout-5 .player-bet.chip-stacks-container {
      top: 18vmin;
      right: 34vmin;
    }

    &.layout-7 {
      top: 8vmin;
      right: -14vmin;

      .player-bet.chip-stacks-container {
        right: 40vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 18vmin;
      right: 30vmin;
    }

    .player-stack {
      top: -1vmin;
      left: 29.4vmin;
    }
  }

  &.seat-6 {
    bottom: 4vmin;
    right: -18vmin;

    &.layout-7 {
      right: -14vmin;

      .player-bet.chip-stacks-container {
        right: 40vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 4vmin;
      right: 32vmin;
    }

    .player-stack {
      top: -1vmin;
      left: 29.4vmin;
    }
  }
}