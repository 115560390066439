.tile-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 1.7vmin;
  height: 85vh;
}

.table-background {
  position: absolute;
  top: 16vh;
  width: 73%;
  height: 61%;
  background-color: #244a67;
  border-radius: 30vh;
  border: solid 2vh black;
  box-shadow: inset 0 0 20px #000000;
  display: flex;
  justify-content: center;
}

.board-content {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 1vh;
}

.player-tile-layout {
  position: absolute;
  width: 40vmin;
  height: 22.5vmin;

  .card-sizer {
    max-width: 6.5vmin;
  }
}

.player-bet.chip-stacks-container {
  position: absolute;
  top: 31vh;
  min-width: 7vmin;
}

.fa-arrow-circle-up {
  color: white;
  background: radial-gradient(#17a2b8 60%,transparent 60%);
}

.fa-arrow-circle-down {
  color: #17a2b8;
  background: radial-gradient(white 60%,transparent 60%);
}

@import './LayoutSeats2.scss', './LayoutSeats3.scss', './LayoutSeats4.scss', './LayoutSeats5.scss', './LayoutSeats6.scss', './LayoutSeats7.scss', './LayoutSeats8.scss';