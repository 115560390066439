.seats-2 {
  &.seat-1 {
    top: -17vmin;
  }

  &.seat-1 {
    &.layout-2 .player-bet.chip-stacks-container {
      right: 4vmin;
      top: 26vh;
    }

    &.layout-4 .player-bet.chip-stacks-container {
      right: -3vmin;
      top: 26vh;
    }

    &.layout-5 .player-bet.chip-stacks-container {
      top: 30vh;
    }

    &.layout-7 .player-bet.chip-stacks-container {
      top: 30vh;
    }
  }
}