.chip-stacks-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 20000;
  font-size: 1.7vmin;
  font-weight: bold;
  height: 7vmin;
}

.chip-stacks-container .chip-amount {
  color: white;
  position: relative;
  cursor: default;
}

.chip-stacks {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.chip-stack {
  position: relative;
  width: 2vmin;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: .2vw;
  margin-right: .2vw;
}

.chip-stack .chip {
  width: 100%;
  position: relative;
}