.seats-4 {
  .layout-7 .card-sizer {
    max-width: 5.5vmin;
  }

  &.seat-1 {
    top: 19vmin;
    left: -22vmin;

    &.layout-7 {
      left: -18vmin;
    }

    .player-bet.chip-stacks-container {
      top: -1vmin;
      left: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 2.4vmin;
    }
  }

  &.seat-2 {
    top: -17vmin;

    &.layout-4 .player-bet.chip-stacks-container {
      top: 26vmin;
      right: -3vmin;
    }
    
    .player-bet.chip-stacks-container {
      top: 30vmin;
      right: 8vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 2.4vmin;
    }
 }

  &.seat-3 {
    top: 19vmin;
    right: -22vmin;

    &.layout-7 {
      right: -18vmin;
    }

    .player-bet.chip-stacks-container {
      top: -1vmin;
      right: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 32.4vmin;
    }
  }
}