.CardContainer {
  position: relative;
  margin-left: .2vw;
	margin-right: .2vw;
  left: 0;
  height: auto;
}

.CardContainer.not-in-best-hand {
  opacity: 0.25;
}

.CardContainer.in-best-hand {
  box-shadow: 0 0 .6vmin .6vmin lightgray;
  z-index: 1000;
}

.CardContainer.in-winning-hand {
  box-shadow: 0 0 .6vmin .6vmin #28a745;
  z-index: 1000;
}

.CardContainer.push-keep {
  box-shadow: 0 0 .6vmin .6vmin #007bff;
  z-index: 1000;
}

.CardContainer:hover .CardBackMask {
  display: none;
}

.Card {
  width: 100%;
  border-radius: 4%;
  border-width: 2%;
}

.CardBackMask {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
}
    
.PlayingCard {
  box-shadow: 0 0.3vh 0.6vh 0 rgba(0,0,0,.8);
  background-color: gray;
}

.PlayingCard.outline {
  opacity: .4;
}

.PlayingCardSelectable {
	cursor: pointer;
}
  
.PlayingCardSelectable:hover {
	opacity: 0.7;
}
  
.PlayingCardSelected {
	cursor: pointer;
	box-shadow: 0 0 .6vmin .6vmin #007bff;
}
  
.PlayingCardFolded {
	opacity: 0.5;
}

.card-sizer {
  perspective: 1000px;
  width: 7vmin;
}

.flip-card-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-inner.flip {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}