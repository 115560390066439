.table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.community-cards .card-sizer {
  max-width: 7vmin;
}

.community-cards-outer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  left: .5vw;
}

.community-cards {
	margin-right: 2vw;
}

.community-cards .CardContainer {
  margin-left: .3vw;
	margin-right: .3vw;
}

.pot.chip-stacks-container {
  font-size: 2.4vmin;
}

.pot.chip-stacks-container .chip-stack {
  width: 2.4vmin;
}

.table-text {
  color: white;
  display: flex;
  align-items: center;
}

.table-text .btn-link {
  color: white;
  font-size: 1.7vmin;
  font-weight: bold;
  text-transform: uppercase;
}

.info-icon {
  cursor: pointer;
  top: 0;
}

.high-low-button {
  cursor: pointer;
  color: white;
  background: none;
}

.table-text.last-action {
  top: 90%;
}

.community-cards-inner {
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
}