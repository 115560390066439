.container {
  max-width: 720px;
}

.select-device-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.video-preview {
  max-width: 220px;
  max-height: 175px;
  border: 1px solid gray;
  background-color:lightgray;
  border-radius: 4px;
}

.select-device-container.oi-video, .select-device-container.oi-microphone {
  top: 5px;
  right: 3px;
  float: right;
  cursor: pointer;
}

.select-device-container.oi-video:hover .select-device-container.oi-microphone:hover {
  color: gray;
}

.off {
  color: gray;
  opacity: .5;
}

.select-device-container.oi-microphone {
  right: 6px;
}