.seats-6 {
  .layout-7 .card-sizer {
    max-width: 5.5vmin;
  }

  &.seat-1 {
    bottom: 4vmin;
    left: -22vmin;
    
    &.layout-5 .player-bet.chip-stacks-container {
      top: 1vmin;
      left: 38vmin;
    }

    &.layout-7 {
      left: -18vmin;

      .player-bet.chip-stacks-container {
        top: 3vmin;
        left: 38vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 1vmin;
      left: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 3.4vmin;
    }
  }

  &.seat-2 {
    top: -4vmin;
    left: -22vmin;

    &.layout-7 {
      left: -18vmin;

      .player-bet.chip-stacks-container {
        top: 23vmin;
        left: 44vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 21vmin;
      left: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 3.4vmin;
    }

    .layout-4 .player-bet {
      left: 37vmin;
    }

    .layout-5 .player-bet {
      left: 40vmin;
    }
 }

  &.seat-3 {
    top: -17vmin;
    
    &.layout-4 .player-bet.chip-stacks-container {
      top: 26vmin;
      right: -3vmin;
    }
    
    &.layout-5 .player-bet.chip-stacks-container {
      top: 30vmin;
      right: 6vmin;
    }
    
    &.layout-7 .player-bet.chip-stacks-container {
      top: 28vmin;
      right: 12vmin;
    }

    .player-bet.chip-stacks-container {
      top: 28vmin;
      right: 6vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 3.4vmin;
    }
  }

  &.seat-4 {
    top: -4vmin;
    right: -22vmin;

    &.layout-7 {
      right: -18vmin;

      .player-bet.chip-stacks-container {
        top: 23vmin;
        right: 44vmin;
      }
    }

    .player-bet.chip-stacks-container {
      top: 21vmin;
      right: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 32.4vmin;
    }

    .layout-5 .player-bet {
      right: 40vmin;
    }
  }

  &.seat-5 {
    bottom: 4vmin;
    right: -22vmin;
    
    &.layout-5 .player-bet.chip-stacks-container {
      top: 1vmin;
      right: 38vmin;
    }
    
    &.layout-7 .player-bet.chip-stacks-container {
      top: 3vmin;
      right: 38vmin;
    }

    &.layout-7 {
      right: -18vmin;
    }

    .player-bet.chip-stacks-container {
      top: 1vmin;
      right: 34vmin;
    }

    .player-stack {
      top: 1vmin;
      left: 32.4vmin;
    }
  }
}