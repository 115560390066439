.modal {
  z-index: 30000;
}

.game-rules {
  max-width: 460px;
}

.game-rules .card-sizer, .game-rules .community-cards .card-sizer {
  max-width: 30px;
}

.game-rules .table {
  background-color: #2a3e4d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 300px;
  border-radius: 10px;
}

.game-rules .table-background {
  flex-direction: column;
  position: relative;
  top: 1%;
  width: 80%;
  height: 74%;
  background-color: #244a67;
  border-radius: 30vmin;
  border: solid 1px black;
  box-shadow: inset 0 0 20px #000000;
}

.game-rules .icons {
  position: absolute;
  top: 23px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-rules .icons svg {
  cursor: pointer;
}

.game-rules .icons .badge {
  cursor: pointer;
}

.game-rules .icons .badge svg {
  position: relative;
  bottom: 1px;
}

.game-rules .icons .highlight {
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #28a745;
}

.game-rules .CardContainer.in-winning-hand {
  box-shadow: 0 0 4px 4px #28a745;
}

.game-rules .low-variant {
  font-size: 18px;
}

.game-rules .icons svg {
  font-size: 1.5em !important;
}

.game-rules .fa-arrow-circle-up {
  color: white;
  background: radial-gradient(#17a2b8 60%,transparent 60%);
}

.game-rules .fa-arrow-circle-down {
  color: #17a2b8;
  background: radial-gradient(white 60%,transparent 60%);
}

.game-rules .community-cards-outer {
  top: -36%;
}

.game-rules .community-cards-inner {
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
}

.game-rules .community-cards .CardRow {
  margin-top: 4px;
  margin-bottom: 4px;
}

.game-rules .CardContainer, .game-rules .community-cards .CardContainer {
  margin-left: 2px;
	margin-right: 2px;
  left: 0;
}

.game-rules .player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-rules .player svg {
  font-size: 1.5em !important;
}

.game-rules .player1 {
  position: relative;
  top: 75%;
}

.game-rules .player2 {
  position: relative;
  bottom: 53%;
}

.game-rules p, .game-rules span {
  text-align: center;
}

.game-rules .hand-composition p {
  margin-bottom: 2px;
}

.game-rules .hand-composition.highlight {
  cursor: pointer;
  color: #28a745;
}

.game-rules ul {
  margin-right: 1em;
}

.game-rules .rounds-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-rules .round {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
}

.game-rules .round .end {
  width: 100%;
}

.game-rules .round .round-number {
  height: 30px;
}

.game-rules .round .round-icon {
  height: 20px;
  cursor: pointer;
}

.tooltip {
  z-index: 500000;
}