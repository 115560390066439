.bet-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2vmin;

  .chip-increments {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 9vmin;
    opacity: 0;
    transition: .5s ease;
    z-index: 1000;
    border-radius: 1vmin;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    width: 10vw;

    .chip-increment {
      display: flex;
      align-items: center;
      margin-top: 1.4vmin;
      margin-bottom: 1.4vmin;
    }

    .amount {
      font-size: 1.7vmin;
      margin-bottom: .6vmin;
    }
  }
}

.chip-badge {
  height: 4vmin;
  width: 4vmin;
  border: .1vmin dashed white;
  border-radius: 50%;
  line-height: 50%;
  font-weight: 500;
  font-size: 1.6vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.increment-button {
  color: lightgray;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .6vmin 0 .6vmin;
  padding: .6vmin;

  &:hover {
    color: white;
  }

  svg {
    font-size: 1.8vmin;
  }
}