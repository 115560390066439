.App {
	min-height: 100vh;
	/* background-color: #31708E; */
	background-color: rgba(41, 60, 75);
	width: 100vw;
	color: white;
}

.LoadingPage {
	width: 100vw;
	height: 100vh;
}

.Session {
	width: 100vw;
	height: 100vh;
	margin: 0px;
}

.Notifications {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  
  width: 300px
}

.landing-layer {
	position: absolute;
	width: 100vw;
	z-index: 50;
	background-color: rgba(41, 60, 75);
}

.game-layer {
	display: flex;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 50;
	background-color: rgba(41, 60, 75);
}

.checklist {
	position: relative;
	top: 20%;
}